.ant-popover-inner {
  border-radius: 12px !important;
}

.ant-tooltip-inner {
  color: #222;
  background-color: white;
  border: 1px solid #222;
  line-height: 15px;
}

.ant-tooltip-arrow {
  display: none;
}

.ant-popover-placement-bottom
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  min-width: 240px;
  max-width: 400px;
  min-height: 160px;
  padding: 20px 0 15px 0;
  margin-top: -20px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  display: none;
}

.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  display: none;
}

.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  max-width: 400px;
  min-height: 300px;
  padding: 16px 0 12px 0;
  margin-top: -40px;
}

.overflow-popover > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content {
  padding: 12px !important;
}

.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-inner {
  padding: 10px;
}

.ant-popover-message-title {
  padding-left: 0;
}

.ant-popover-buttons > .ant-btn-dangerous {
  margin: 0 12px 0 0;
}

.ant-image-mask {
  border-radius: 12px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b1b1b1 #e0e6eb;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #e0e6eb;
}

*::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 5px;
  border: 1px solid #e0e6eb;
}

th.ant-table-cell.ant-table-cell-scrollbar {
  border: none !important;
  box-shadow: none;
}

.planny-link {
  color: rgba(0, 0, 0, 0.85);
}

.planny-link:hover {
  color: #2196f3;
}

.ant-picker-calendar-date-content > ul {
  padding-left: 4px;
}

/* ********* Card styling ********  */

.ant-card {
  border: none;
  border-radius: 8px;
}

.ant-card-head-title {
  padding: 12px;
}

.ant-card-head {
  border: none;
  font-size: 18px;
  padding-left: 12px;
}

.ant-card-actions {
  border: none;
  border-radius: 8px;
}

/* ********* Card styling ********  */

.ant-table-column-title {
  position: static;
}

.divider {
  cursor: auto;
  background-color: #fff !important;
}

button {
  border-radius: 8px !important;
}
